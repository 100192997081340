@import '../../../App.scss';

.description {
    font-family: $font_family;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.4);
}

.configuration-heading {
    font-family: $font_family;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 16px;
    color: rgba(5, 5, 82, 0.4);
}

.toggle {
    font-family: $font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);
}

.text-input-wrapper {
    margin-bottom: 20px;
}

.configuration {
    &_placeholder {
        font-family: "Inter";
        font-style: italic;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.05em;
        margin-top: 16px;
        margin-bottom: 16px;
        color: rgba(5, 5, 82, 0.4);
    }
}