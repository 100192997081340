@import '../../../App.scss';


.description-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 25px;

  &:has(input:disabled)>&__label {
    color: rgba(5, 5, 82, 0.4);
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);
    font-family: $font_family;

    img {
      margin-left: 5px;
      vertical-align: middle;
      height: 12px;
      width: 12px;
    }
  }

  .toggle {
    margin-left: 1rem;

    label {
      display: block;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: rgba(5, 5, 82, 0.8);
    }

    input {
      display: none;

      &:disabled:checked+.slider {
        background-color: rgba(1, 191, 131, 0.25);
        cursor: not-allowed;
      }

      &:disabled:not(:checked)+.slider {
        background-color: #05055214;
        cursor: not-allowed;
      }

      &:checked+.slider {
        background-color: #01BF83;
      }

      &:checked+.slider:before {
        transform: translateX(10.5px);
      }
    }

    .slider {
      display: flex;
      justify-content: flex-start;
      padding-left: 3px;
      align-items: center;
      width: 32px;
      height: 20px;
      border-radius: 34px;
      background-color: #05055233;
      border: none;
      transition: background-color 0.2s;
      box-shadow: inset 0 0 2px #efecec66;

      &::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #ffffff;
        transition: transform 0.2s;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      }
    }

    .slider.round {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
      }
    }
  }
}

.elements {
  max-width: 100%;
  color: rgba(5, 5, 82, 0.8);
}