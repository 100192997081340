@import '../../../App.scss';

.title-and-dropdowns {
    font-family: $font_family;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4px;
    gap: 8px;

    &--disabled &__title {
        color: rgba(5, 5, 82, 0.4);
    }

    &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
        margin-bottom: 0px;

        img {
            margin-left: 5px;
            vertical-align: middle;
            height: 12px;
            width: 12px;
        }
    }

    &__dropdowns {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}

.inputs-dropdown-row {
    font-family: $font_family;
    height: 47px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 50%;

    &__title {
        font-style: normal;
        font-weight: 400;
        font-family: $font_family;
        font-size: 12px;
        width: fit-content;
        line-height: 15px;
        background-color: white;
        letter-spacing: 0.01em;
        transform: translateX(12px) translateY(6.5px);
        color: rgba(5, 5, 82, 0.6);
    }

    &__options_source {

        font-family: $font_family;
        appearance: none;
        background: url('../../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
        padding: 6px 20px 6px 12px;
        font-size: 14px;
        color: #333;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 32px;
        border: 1px solid rgba(5, 5, 82, 0.1);
        border-radius: 5px;
        margin-right: 18px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: rgba(5, 5, 82, 0.8);

        &:disabled {
            color: rgba(5, 5, 82, 0.4);
            cursor: not-allowed;
        }
    }

    &:has(.inputs-dropdown-row__options_source:disabled)>&__title {
        color: rgba(5, 5, 82, 0.4);
    }

    &__options_variable {
        font-family: $font_family;
        appearance: none;
        background: url('../../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
        padding: 6px 20px 6px 12px;
        font-size: 14px;
        color: #333;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 32px;
        border: 1px solid rgba(5, 5, 82, 0.1);
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: rgba(5, 5, 82, 0.8);

        &:disabled {
            color: rgba(5, 5, 82, 0.4);
            cursor: not-allowed;
        }
    }

    &:has(.inputs-dropdown-row__options_variable:disabled)>&__title {
        color: rgba(5, 5, 82, 0.4);
    }
}