.accordion {
  border: 1px solid #0505521A;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    &--open {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #0505520D;
      }
    }

    &-button {
      background: none;
      border: none;
      cursor: pointer;
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  &__title {
    width: 100%;
  }

  &__icon {
    transition: transform 0.2s ease;
    width: 32px;
    height: 32px;

    &--open {
      transform: rotate(90deg);
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    &--open {
      max-height: 350px;
      margin-top: 12px;
      overflow-y: auto;
    }
  }
}