@import '../../../App.scss';

.title-table {
  h2 {
    background-color: rgba(5, 5, 82, 0.03);
    padding: 0.5em;

  }

  &_container {
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: rgba(5, 5, 82, 0.03);
  }


  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    background-color: var(--border-grey);
    border: none;
    width: 100%;
    height: 100%;

    th,
    td {
      padding: 0.5em;
      height: 39px;
      border-bottom: 1px solid #ddd;
    }

    th {
      margin-bottom: 1em;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      font-family: $font_family;
      color: rgba(5, 5, 82, 0.8);
      // background: rgba(5, 5, 82, 0.03);
    }

    td {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: #ffff;
      letter-spacing: 0.01em;
      font-family: $font_family;
      color: rgba(5, 5, 82, 0.6);
    }
  }
}

.output-variable-name {
  font-family: 'IBM Plex Mono', monospace !important;
  font-weight: 500;
  color: #de4a70 !important;
}