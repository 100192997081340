@import '../../App.scss';
@import '../../components/Workflow.scss';

.module_builder {
  &_inputs_drawer {
    &__overlay {
      @extend .workflow_configuration_modal__overlay;
    }

    &__body {
      @extend .workflow_configuration_modal__body;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }

    &__headerContainer {
      @extend .workflow_configuration_modal__header;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
    }

    &__headerContent {
      @extend .workflow_configuration_modal__headerContent;
    }

    &__headerCloseButton {
      @extend .closeModalButton;
      background-color: transparent;
    }

    &__bottomContent {
      height: 54px;
      width: 100%;
      display: flex;
      padding-inline: 16px;
      border-top: 1px solid rgba(5, 5, 82, 0.1);
      left: 0px;
      bottom: 0px;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
    }

    &__navButton {
      @extend .nav_button;
    }
  }

  &__inputs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    overflow-y: auto;
    flex-grow: 1;
  }

  &-textfield {
    margin-bottom: 20px;
  }

  &_property {
    &__input_container {
      padding: 4px;

      & .edit-properties-div__label {
        padding: 0;
        width: 100%;
        font-family: "Inter";
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
        height: 20px;
        display: flex;
        align-items: center;
      }

      & .edit-settings-div__toggle {
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      & .edit-properties-div__text-input-container {
        margin-bottom: 0;
      }

      & .edit-properties-div__json-editor-container {
        display: flex;
      }

      & .text-input-div {
        padding: 0;
      }

      & .validation-drawer-div__heading {
        font-weight: normal;
      }
    }
  }

  &__input_button {
    @extend .workflowInput__bottomContent_button;
    flex-grow: 1;

    &_container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}