.events-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  margin-bottom: 20px;
  .event-item {
    cursor: pointer;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & img {
      height: 20px;
      width: 20px;
    }
    &-title {
      font-family: "Inter";
    margin-top: 8px;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 2px;
    border-radius: 6px;
    border: 1px solid #0505521A;
    margin-bottom: 24px;
  }
  &__list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    &:hover{
      background-color: #05055208;
    }
  }
  &__list-item-event-name{
    //styleName: Secondary text;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #050552CC;
  }
  &__list-item-event-action-container{
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &__list-item-event-action-more{
    cursor: pointer;
    border: none;
    background: none;
    height: 20px;
    width: 20px;
    padding: 0;
    &:hover{
      background-color: #E6E6E6;
    }
  }
  &__add-event-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  &__delete-menu{
    display: flex;
    align-items: center;
    gap: 2px;
    width: 143px;
    height: 48px;
    padding: 8px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #0505521A;
    box-shadow: 0px 4px 8px 0px #00000029;
  }
  &__delete-menu-option{
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 9.5px 8px 9.5px 8px;
    border-radius: 4px;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #BF2600;
  }
}
.events-dropdown {
  position: absolute;
  width: 148px;
  display: flex;
  right: 0px;
  top: 25px;
  gap: 4px;
  padding: 4px;
  background-color: white;
  flex-direction: column;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px #00000029;
  z-index: 1000;
}

.event-option {
  padding: 7px 8px 7px 12px;
  border: none;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #333333;
}

.event-option:hover {
  background-color: #f5f5f5;
}
