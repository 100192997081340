@import '../../../containers/FormModule/FormModuleDrawer.scss';

$chrome-picker-width: 225px;
$color-input-width: 129px;

.color-picker {
    display: grid;
    width: 100%;
    height: 100%;

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        position: relative;
        cursor: pointer;
        gap: 40px;
    }

    &__label {
        width: 96px;
        height: 15px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &__subcontainer {
        display: flex;
        flex-direction: row;
        width: 168px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        background-color: rgba(5, 5, 82, 0.04);
        border-radius: 6px;
    }

    &__preview {
        width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  flex-shrink: 0;
  background-clip: content-box;
  &__body{
    border-radius: 3px;
    width: 100%;
    height: 100%;

  }
  &__opacity{
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 6px;
  font-family: Inter;
  font-size: 12px;

  }
    }

    &__chrome-picker {
        position: absolute;
        z-index: 9999999999999 !important;
        top: 100%;
        left: 10%;
    }
}

.button-color-picker {
    display: flex;
    width: 100%;
    height: 100%;
    margin-right: 0px;
    margin-bottom: 8px;

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }

    &__label {
        // @extend .edit-properties-div__label;
        flex-basis: 33.33%;

    }

  
}
.color-picker__chrome-picker {
    transform: translateX(0) !important;
}

.color-input {
    width: 78px;
    height: 31px;
    padding: 12px;
    color: rgba(5, 5, 82, 0.80);
    border: none;
    background: transparent;
    font-family: Inter;
  font-size: 12px;
  gap: 8px;
  padding: 9px 5px;

    &:focus {
        outline: none;
    }
}
.color-input2 {
    width: 30px;
    height: 31px;
    padding: 12px;
    color: rgba(5, 5, 82, 0.80);
    border: none;
    background: transparent;
    font-family: Inter;
  font-size: 12px;
  gap: 8px;
  padding: 9px 5px;

    &:focus {
        outline: none;
    }
}
/* Hide the spinner arrows for number inputs */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}