@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;500&family=IBM+Plex+Mono:wght@200;300;500&family=Inter:wght@100;200;300;400;500;600;700&display=swap');
$background_color: #00034d;
$background_blue: #f6f7ff;
$hyperblue: #00034d;
$hyperYellow: #ffdc47;
$grey_border: #c2c4cb;
$grey_border2: #dbdbdb;
$clickable_blue: #335aff;
$border_grey: #ffff;
$success_green: #01bf83;
$place_holder_text: #a7a7a7;
$select_blue: #ebedff;
$night-blue-20: rgba(5, 5, 82, 0.2);

$font_family: 'Inter';

* {
  box-sizing: border-box;
}

body {
  background-color: #f6f7ff;
  font-family: $font_family;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100vh;
}


#main {
  &__container {
    height: 100%;
    overflow: auto;
    &__body {
      height: 100%;
      overflow: auto;
      background-color: white;

      &__generic {
        padding: 32px 32px;
        height: 100%;
      }
    }
  }
}

.font-design-heading {
  color: $background_color;
  font-family: $font_family;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.035em;
  text-align: left;
}

.font-design-sub-heading {
  font-family: $font_family;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $background_color;
}