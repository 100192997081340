.workflow-update-confirmation-modal {
  &__header {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: rgba(5, 5, 82, 0.8);
    margin: 0;
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(5, 5, 82, 0.6);

    &__updates {

      &__item {
        margin-bottom: 8px;
      }
    }
  }
}