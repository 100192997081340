.ui-action-modal {
  &__header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: rgba(5, 5, 82, 0.8);
    margin: 0;
    display: flex;
    gap: 4px
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(5, 5, 82, 0.6);

    &__message {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(5, 5, 82, 0.8);
    }

    &__dependencies {
      padding-left: 0;

      &__item {
        margin-left: 32px;
      }
    }
  }
}