@import '../../App.scss';

.collapsible-div {
    border-radius: 6px;
    border: solid 1px rgba(5, 5, 82, 0.1);
    margin: 12px 0px;

    &_error {
        border: 1px solid rgba(202, 33, 21, 0.8);
    }
}

.collapsible-header {
    padding: 10px;
    background-color: rgba(5, 5, 82, 0.03);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
    font-family: $font_family;
    justify-content: space-between;
}

.collapsible_content {
    padding: 10px 16px;
    font-family: $font_family;

    &_collapsed {
        display: none;
    }
}

.drop-down-icon {
    margin-right: 12px;
}

.delete-icon {
    background-color: unset;
    border: none;
    cursor: pointer;
}