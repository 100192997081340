@import '../../../App.scss';

.condition-properties-div {
    font-family: $font_family;
    color: rgba(5, 5, 82, 0.8);

    &__title {
        font-size: 0.75rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
    }

    &__condition-button {
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.8);
        width: 100%;
        height: 1.9375rem;
        margin: 0.75rem 0px;
        border-radius: 0.375rem;
        box-shadow: 0 1px 0.125rem 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        text-align: left;
        background-color: white;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
            color: gray;

        }
    }

    &__swap-button {
        font-family: "Inter";
        color: #554ef1;
        width: 100%;
        height: 1.9375rem;
        margin: 0.75rem 0px;
        border-radius: 0.375rem;
        box-shadow: 0 1px 0.125rem 0 rgba(5, 5, 82, 0.06);
        border: solid 1px #554ef1;
        text-align: center;
        background-color: white;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
            color: gray;

        }
    }
}

.search-module {
    opacity: 1;
    width: 246px;
    height: 393px;
    position: absolute;
    flex-grow: 0;
    border-radius: 0.3125rem;
    box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    background-color: white;
    overflow: scroll;
    z-index: 99999 !important;
    cursor: pointer;

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        // background-color: rgba(5, 5, 82, 0.2);
        backdrop-filter: blur(0.25%);
    }

    &__modules {
        height: 31px;
        width: 100%;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        &:hover {
            background-color: rgba(5, 5, 82, 0.1);
        }

        &__name {
            margin-left: 12px;
            font-family: $font_family;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
            cursor: pointer;
        }

        &__icon {
            margin-right: 12px;
        }
    }

}

.search-variables {
    position: absolute;
    opacity: 1;
    width: 246px;
    max-height: 190px;
    margin-bottom: 16px;
    margin-top: 16px;
    flex-grow: 0;
    border-radius: 0.3125rem;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba(5, 5, 82, 0.1);
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    background-color: white;
    overflow: scroll;
    z-index: 99999 !important;

    cursor: pointer;

    &__name {
        font-family: $font_family;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        line-height: 31px;
        padding-left: 16px;
        cursor: pointer;

        &:hover {
            background-color: rgba(5, 5, 82, 0.1);
        }
    }
}

.condition {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(1, 1, 28, 0.8);
    z-index: 9999 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        background-color: white;
        opacity: 1;
        width: 90%;
        height: 90%;
        max-width: 920px;
        max-height: 600px;
        position: absolute;
        border-radius: 0.375rem;
        box-shadow: 0 0 0.9375rem 0 rgba(5, 5, 82, 0.1);
        padding-block: 9px;
        padding-inline: 1em;

        &__rules {
            height: inherit;
            overflow: scroll;

            &__add {
                margin-top: 16px;
                margin-bottom: 16px;
                font-family: $font_family;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #554ef1;
                cursor: pointer;
                width: fit-content;
            }
        }

        &__title-ribbon {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding-bottom: 4px;

            &__title {
                font-family: $font_family;
                color: rgba(5, 5, 82, 0.8);
                font-size: 14px;
                font-weight: 600;
            }

            &__button {
                background-color: #554EF1;
                color: #fff;
                border: #554EF1;
                height: 1.9375rem;
                width: 7rem;
                font-weight: 500;
                font-size: 12px;
                border-radius: 0.3125rem;
                cursor: pointer;
            }

            &__close-div {
                display: flex;
                justify-content: space-between;
                margin-right: 0.5rem;
                gap: 2em;
                align-items: center;
                cursor: pointer;
            }
        }

        &__body {
            margin-top: 1rem;
            font-family: $font_family;
            font-size: 0.75rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
            padding-bottom: 2.5rem;
            height: 90%;
        }

        &__rule-box {
            width: 100%;
            margin-top: 1rem;
            height: 2rem;
            padding: 0 0.75rem 0 0;
            border-radius: 0.375rem;
            box-shadow: 0 1px 0.125rem 0 rgba(5, 5, 82, 0.06);
            border: solid 1px rgba(5, 5, 82, 0.1);
            display: flex;
            align-items: center;

            &__title {
                height: 100%;
                display: flex;
                width: 56px;
                padding-left: 0.75rem;
                border-right: solid 1px rgba(5, 5, 82, 0.1);
                box-shadow: 0 0 0.9375rem 0 rgba(5, 5, 82, 0.1);
                background-color: rgba(85, 78, 241, 0.06);
                align-items: center;
            }

            &__input {
                width: 100%;
                height: 100%;
                border: none;
                padding-left: 0.5rem;
                letter-spacing: 0.125rem;
                font-family: $font_family;
                font-size: 0.75rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &:focus {
                    outline: none;
                }
            }

            &__sample-rule {
                letter-spacing: normal;
                text-transform: capitalize;
                margin-left: 8px;
                width: 190px;
                font-size: 10px;
                color: rgb(5 5 82 / 52%);
            }
        }

        &__where {
            margin-block: 1rem;
            padding-left: 4px;
        }

        &__var {
            width: 100%;
            margin-top: 1rem;
            height: 3.4375rem;
            padding: 0 0.75rem 0 0.75rem;
            border-radius: 0.375rem;
            border: solid 1px rgba(5, 5, 82, 0.1);
            display: flex;
            align-items: center;
            background-color: rgba(85, 78, 241, 0.06);

            &__delete {
                background-color: inherit;
                border: none;
                cursor: pointer;
            }

            &__delete-copy-div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__delete-copy-icon {
                height: 14px;
                width: 12px;
                margin-bottom: 4px;
                margin-top: 4px;
            }

            &__textBox {
                height: 1.9375rem;
                width: 95%;
                margin-left: 0.75rem;
                font-family: $font_family;
                font-size: 0.75rem;
                text-align: left;
                border: solid 1px rgba(5, 5, 82, 0.1);
                border-radius: 0.375rem;
                padding-left: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 8px;
                cursor: pointer;
                background-color: white;

                &__more_button {
                    background-color: white;
                    border: none;
                    cursor: pointer;
                }

                &_input {
                    border: none;
                    height: 100%;
                    width: 95%;
                    align-items: center;
                    border-radius: 0.375rem;
                    background-color: inherit;
                    padding-left: 0.5rem;
                    border-radius: 0.375rem;
                    color: rgba(5, 5, 82, 0.8);

                    &:focus {
                        outline: none;
                    }
                }
            }

            &__name {
                width: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__var {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            &__dropDown {
                height: 1.9375rem;
                width: 95%;
                margin-left: 0.75rem;
                font-family: $font_family;
                font-size: 0.75rem;
                text-align: left;
                border-radius: 0.375rem;
                border: solid 1px rgba(5, 5, 82, 0.1);
                padding-left: 0.5rem;
                appearance: none;
                background: url('../../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 0.75rem;
                display: flex;
                align-items: center;
                color: rgba(5, 5, 82, 0.8);
                cursor: pointer;
                background-color: white;

                &:focus {
                    outline: solid 0.5px #554ef1;
                }

                &__module {
                    margin-right: 0.75rem;
                    color: rgba(5, 5, 82, 0.8);
                }

                &__var-div {
                    display: flex;
                }

                &__side-icon {
                    margin-right: 0.75rem;
                    color: rgba(5, 5, 82, 0.8);
                }
            }
        }
    }
}

.active {
    backdrop-filter: unset;
    position: relative;
    z-index: 99 !important;
    color: rgba(5, 5, 82, 0.8);
}

.active_module {
    background-color: rgba(5, 5, 82, 0.1);
}

.choose-input-options-modal {
    height: fit-content;
    width: 245px;
    cursor: pointer;
    position: absolute;
    z-index: 99 !important;
    background-color: rgba(255, 255, 255);
    opacity: 1;
    border-radius: 0.375rem;
    box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
    border: solid 1px rgba(5, 5, 82, 0.1);

    &__options {
        line-height: 31px;
        font-family: $font_family;
        font-size: 0.75rem;
        color: rgba(5, 5, 82, 0.8);
        padding-left: 8px;
        margin-bottom: 6px;
        font-weight: normal;

        &:hover {
            background-color: rgba(5, 5, 82, 0.1);
        }
    }

    &__background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.invalid-rule {
    border: #f1544e;
}

.conditional__content {

    &__var {
        width: 100%;
        margin-top: 1rem;
        height: 3.4375rem;
        padding: 0 0.75rem 0 0.75rem;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
    }

    &__rules {
        height: inherit;
        overflow: scroll;
        width: 85%;
        margin-left: 42px;

        &_info {
            color: red;
            background-color: #e6e6e647;
            border-radius: 0.375rem;
            box-shadow: 0 1px 0.125rem 0 rgba(5, 5, 82, 0.06);
            border: solid 1px rgba(5, 5, 82, 0.1);
            padding: 8px;
            display: flex;
            gap: 4px;
            flex-direction: column;
            font-size: 12px;
            overflow-x: auto;

            &_heading {
                font-weight: bold;
            }
        }
    }

    &__rule-box {
        width: 50%;
        margin-top: 1rem;
        margin-left: 16px;
        height: 2rem;
        padding: 0 0.75rem 0 0;
        border-radius: 0.375rem;
        box-shadow: 0 1px 0.125rem 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        display: flex;
        align-items: center;
    }

    &__where {
        margin-block: 1rem;
        padding-left: 4px;
        margin-left: 42px;
    }
}