@import '../../../App.scss';

.multi-select-wrapper-dropdown {
    &__container {
        margin-bottom: 26px;
    }

    &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        font-family: $font_family;
        color: rgba(5, 5, 82, 0.8);
        margin-bottom: 16px;

        img {
            margin-left: 5px;
            vertical-align: middle;
            height: 12px;
            width: 12px;
        }
    }
}
