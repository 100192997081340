.search_box {
  position: relative;
  width: 100%;
  height: 100%;

  &__icon {
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: 99;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 20px;
  }

  &__input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 12px 12px 12px 32px;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
    border: solid 1px rgba(5, 5, 82, 0.1);
    outline: none;
    color: rgba(5, 5, 82, 0.4);
    font-size: 13px;
    overflow: scroll;
  }

  &__results {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    z-index: 100;
    background-color: white;
    border-radius: 6px;
    padding-left: 3px;
    font-size: 13px;
    color: rgba(5, 5, 82, 0.6);
    display: flex;
    height: 30px;
  }
}