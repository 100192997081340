.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__mark {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-image: url('../../../assests/icons/checkMark.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__input:checked+&__mark {
    background-color: #554EF1;
    border-color: #554EF1;

    &::after {
      display: block;
    }
  }

  &__label {
    cursor: pointer;
  }
}