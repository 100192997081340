.primary-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: #554EF1;

  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: darken(#554EF1, 10%);
  }

  &:disabled {
    background-color: #F5F5F7;
    cursor: not-allowed;
    color: #0505524D;
  }
}