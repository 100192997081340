.dropdown {
    position: relative;
    padding: 5px 15px;
    height: 2rem;
    border: solid 1px rgba(5, 5, 82, 0.1);
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: 100%;
}
.dropdown_custom-Input {
    position: relative;
    height: 2rem;
    border: solid 1px rgba(5, 5, 82, 0.1);
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: 100%;
}

.dropdown-header {
    background-color: white;
    cursor: pointer;
    width: 100%;
    border: none;
    font-family: "Inter";
    font-size: 12px !important;
    color: rgba(5, 5, 82, 0.8);
    font-weight: 500;
}
.dropdown-header_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.dropdown-content {
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 205px;
    min-height: 205px;
    overflow-y: auto;  /* Enable vertical scrolling */
    z-index: 1;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 6px;
    right: -2px;
    top: 35px;
    font-size: 13px;
    font-family: Inter;
    color: rgba(5, 5, 82, 0.8);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    &__first-section{
        min-height: 115px;
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 1;
        border: 1px solid rgba(5, 5, 82, 0.1);
        border-radius: 6px;
        right: -2px;
        top: 35px;
        font-size: 13px;
        font-family: Inter;
        color: rgba(5, 5, 82, 0.8);
        padding-top: 4px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    }
}

.dropdown-content.show {
    display: block;
}

.breadcrumb {
    display: flex;
    align-items: center;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
}

.breadcrumb span {
    font-weight: 500;
}

.search-bar {
    width: 100%;
    border: none;
}

.items-list {
    list-style-type: none;
    padding: 0px 4px 4px 4px;
    margin: 0;
}

.item {
    padding: 8px 8px 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 1;
    font-family: Inter;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.item-button {
    border: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    cursor: pointer;
    color: rgba(5, 5, 82, 0.8);
    font-family: Inter;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    padding: 0;
}
.item-selected{
    background-color: rgba(85, 78, 241, 0.05);
}
.items-container.item-selected:hover {
    background-color: rgba(85, 78, 241, 0.05); /* override hover effect */
}

.items-container:hover {
    background-color: rgba(5, 5, 82, 0.03);
}

.item.disabled {
   display: none;
}
.search-bar:focus {
    outline: none;
}
.search-bar-container{
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 4px;
    border-bottom: 1px solid rgba(5, 5, 82, 0.05);
}
.search-bar-container input {
    font-family: Inter;
    font-size: 13px;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
   
}
.items-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding-right: 10px;
}
.item-icom{
    height: 20px;
}
.empty-message-container{
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 10px;
    flex-direction: column;
}
.empty-message-icon{
    display: flex;
    justify-content: center;
}
.empty-message-text{
    display: flex;
    justify-content: center;
}
.long-text{
    word-break: break-all;
}
