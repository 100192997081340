.module-update {
  position: relative;
  display: inline-block;

  &__button {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;

    padding-inline: 8px 12px;
  }

  &__notification {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 8px;
    height: 8px;
    background-color: #554ef1;
    border-radius: 50%;
    z-index: 1;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &-modal {
    width: 720px;
    max-width: 90%;
    height: calc(100% - (2 * (56px + 10px)));
    max-height: 100%;
    overflow-y: auto;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #050552CC;
        margin: 0;
      }

      &-subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #05055299;
        margin: 0;
      }
    }
  }
}