@import '../../../App.scss';


.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
  left: 0;
  background: rgba(0, 3, 77, 0.6)
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  height: 460px;
  opacity: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  &__countries-flag {
    width: 24px;
    border-radius: 80px;
    margin-right: 12px;
  }

  &__countries-and-flag {
    display: flex;
    flex-direction: row-reverse;
  }

  &__title {
    font-family: $font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding-inline: 16px;
    padding-top: 16px;
    padding-bottom: 12px;
    line-height: 19px;
    color: #050552;
  }

  &__searchdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-inline: 16px;
    gap: 1em;

    &__searchbardiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid rgba(5, 5, 82, 0.1);
      border-radius: 5px;
      padding-left: 8px;
      flex-grow: 1;
    }

    &__searchbar {
      font-family: $font_family;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      outline: none;
      flex-grow: 1;
      /* identical to box height */
      color: rgba(5, 5, 82, 0.4);
      height: 32px;
      border: none;
      background: #FFFFFF;
      padding: 8px;
      text-transform: capitalize;

      &::placeholder {
        text-transform: none;
      }
    }

    &__selectAll-button {
      border: none;
      font-family: $font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /* identical to box height */
      color: rgba(5, 5, 82, 0.8);
      cursor: pointer;
    }

    &__deselectAll-button {
      border: none;
      font-family: $font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /* identical to box height */
      color: rgba(5, 5, 82, 0.8);
      cursor: pointer;
    }
  }

  &__documents-checklist-div {
    display: flex;
    overflow: auto;
    flex-grow: 1;
    gap: 24px;
    padding: 16px;
    border-top: 1px solid rgba(5, 5, 82, 0.1);
    border-bottom: 1px solid rgba(5, 5, 82, 0.1);
    font-size: 12px;
    font-weight: 500;
    font-family: $font_family;


    &__country {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      cursor: pointer;

      &__label {
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
      }
    }

    &__countryHeading {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: rgba(5, 5, 82, 0.03);
      height: 40px;

      &__label {
        width: -webkit-fill-available;
      }
    }

    &__documents {
      display: flex;
      padding-left: 52px;
      flex-direction: row-reverse;
      justify-content: space-between;
      background: white;
      cursor: pointer;

      &:hover {
        background: rgba(85, 78, 241, 0.1);
      }

      &__label {
        width: -webkit-fill-available;
      }
    }
  }

  &__documents_select-checklist-div {
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex-grow: 1;
    border-top: 1px solid rgba(5, 5, 82, 0.1);
    border-bottom: 1px solid rgba(5, 5, 82, 0.1);
    font-size: 12px;
    font-weight: 500;
    font-family: $font_family;

    &__country {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: rgba(5, 5, 82, 0.03);
      height: 40px;
      padding: 16px;

      &__label {
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
      }
    }

    &__documents {
      all: unset;
      display: flex;
      gap: 24px;
      align-items: center;
      background: white;
      padding: 16px;
      height: 8px;

      &_button {
        all: unset;
        cursor: pointer;

      }

      &_container {
        border-bottom: 1px solid rgba(5, 5, 82, 0.03);
      }

      &:hover {
        background: rgba(85, 78, 241, 0.1);
      }

      &_item {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      &_label {
        flex-grow: 1;
      }

      &_dropdown {
        padding: 16px;

        &__side {
          padding-left: 24px;

          &_heading {
            font-weight: 600;
            color: rgb(52 52 115 / 51%);
            text-transform: uppercase;
          }

          &_options {
            display: flex;
            flex-direction: column;

            &_label {
              font-size: 12px;
              font-weight: 500;
              display: flex;
              align-items: center;
              padding-block: 4px;
              display: flex;

              &__name {
                flex-grow: 1;
              }
            }
          }
        }

      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }

  &__cancel-button {
    width: 78px;
    height: 32px;
    background: white;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    font-family: $font_family;
    color: rgba(5, 5, 82, 0.8);
    border: 1px solid rgba(5, 5, 82, 0.2);
    margin-right: 16px;
  }

  &__confirm-button {
    width: 78px;
    height: 32px;
    background: #554EF1;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    font-family: $font_family;
    border: none;
    color: #F9F9F9;
  }

  &__documents-checklist-div {
    display: flex;
    flex-direction: column;
  }

  &__checklist {
    cursor: pointer
  }
}

.document-select {
  font-family: $font_family;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);
    padding-bottom: 8px;

    img {
      margin-left: 5px;
      vertical-align: middle;
      height: 12px;
      width: 12px;
    }
  }

  &__btn {
    width: 129px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #554EF1;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    margin-top: 8px;
    margin-bottom: 16px;
    color: #554EF1;
    padding: 8px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &:has(.document-select__btn:disabled)>&__title {
    color: rgba(5, 5, 82, 0.4);
  }
}