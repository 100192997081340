.version-update {
  height: calc(100% + 32px);
  overflow-y: auto;
  margin-top: -16px;
  margin-inline: -16px;

  display: flex;
  flex-direction: column;

  &__search {
    height: 48px;
    padding: 12px 16px 12px 16px;

    display: flex;
    align-items: center;
    gap: 10px;

    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #0505521A;
    display: flex;
    align-items: center;

    &-input {
      width: 100%;
      font-size: 14px;
      border: none;
      outline: none;
      color: #05055266;

      &::placeholder {
        color: #05055266;
      }
    }
  }

  &__warning {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;

    background: #FCF4E4;

    &-icon {
      height: 24px;
      width: 24px;
    }

    &-text {
      color: #B78119;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }

  &__no-results {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &-icon {
      height: 42px;
      width: 42px;
    }

    &-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #05055266;
    }
  }

  &__item {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px 16px;
    margin: 0;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    &-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #050552CC;
      margin: 0;

      &-wrapper {
        display: flex;
        gap: 4px;
      }
    }

    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #05055299;
      margin: 0;
    }

    &-version {
      color: #05055299;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 2px;
      height: fit-content;
    }

    &-details {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 4px 12px;

        &:first-child {
          padding-top: 12px;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding: 0;
        padding-left: 20px;
      }

      &-description {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;

        color: #050552CC;
      }

      &-list-item {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;

        color: #05055299;
      }
    }
  }

  &__update-button {
    height: 36px;

    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  &__selected-item {
    &-details {
      padding: 20px 40px 20px 40px;
      height: 100%;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      gap: 36px;

      &-wrapper {
        @extend .version-update__item-details-wrapper;
        gap: 20px;

        &:first-child {
          padding-top: 4px;

          &::before {
            display: none;
          }
        }
      }

      &-list {
        @extend .version-update__item-details-list;
      }

      &-description {
        @extend .version-update__item-details-description;
      }

      &-list-item {
        @extend .version-update__item-details-list-item;
      }
    }

    &-footer {
      height: 116px;
      border-top: 1px solid #0505520D;
      padding: 12px;
      border-radius: 0px 0px 8px 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-actions {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }

    &-update-consent {
      height: 44px;
      width: 100%;
      padding: 12px;
      border-radius: 4px;

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      color: #05055299;
      background: #05055208;
    }

    &-update,
    &-cancel {
      height: 36px;
      padding: 10px 12px;
      color: #05055299;
      font-size: 13px;
      font-weight: 500;
    }
  }
}