.configure_container {
  display: flex;
  position: relative;

  .configure_button {
    width: 200px;
    height: 32px;
    min-width: 64px;
    padding: 11.5px 12px;
    gap: 4px;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #05055233;
    background-color: #ffffff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #050552CC;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.webview-button {
      border-radius: 6px;
      margin-bottom: 4px;
    }

    &:hover {
      background-color: #05055208;
      border: 1px solid #0505524D;
    }
  }

  .configure_arrow {
    width: 28px;
    height: 32px;
    padding: 11.5px 4px;
    gap: 4px;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #05055233;
    border-left: none;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #050552CC;
    cursor: pointer;
    &:hover {
      background-color: #05055208;
      border: 1px solid #0505524D;
    }
  }

  .dropdown {
    position: absolute;
    width: 217px;
    height: 48px;
    top: 38px;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #0505521A;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px #00000029;
    z-index: 1000;
    padding: 8px;
    gap: 4px;

    .dropdown_option {
      color: rgba(5, 5, 82, 0.8);
      border: none;
      border-radius: 4px;
      background-color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      width: 201px;
      height: 32px;
      padding: 9.5px 8px 9.5px 12px;
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}