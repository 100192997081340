/* src/components/ConfigureEvent.css */

.configure-event {
  background: white;
  border-radius: 6px;
  width: 710px;
  height: 394px;
  position: relative;
  bottom: 215px;
  right: 20px;
  box-shadow: 0px 6px 20px 0px #0301321A;
  border: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 12px 16px 12px 8px;
    gap: 0px;
    border-bottom: 1px solid #E6E6E6;
    &-content {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  &__control-group-target-dropdown{
    .dropdown{
      width: 170px;
    }
  }
  &__section{
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #FCFCFC;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    &__add-button{
      display: flex;
      padding: 4px 12px 4px 8px;
      gap: 4px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin-left: 16px;
      width: 138px;
      border: none;
      background: none;
      cursor: pointer;
      &:hover{
        background: #F2F2F2;
      }
    }
    &__add-button-text{
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
    &__header-content{
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;  
    }
    &__delete-button{
      border: none;
      background: none;
      cursor: pointer;
    }
  }
  &__section-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__section-header{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #616161;

  }
  &__title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__close-button {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__left-panel {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;
    height: 100%;
  }

  .event-section {
    padding: 0.75rem;
    height: 52px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    select {
      width: 100%;
    }
  }

  .rules-section {
    padding: 12px 8px 0px 8px;
    width: 252px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    min-height: 0;
  }

  .auto-height-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    // Main vertical line that runs through everything
    &::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 28px;
      // Only extend to the last rule-group's bottom
      height: calc(100% - 45px); // Adjust this value if needed
      width: 2px;
      background-color: #E6E8F0;
      z-index: 1;
    }
  }

  .rule-group {
    position: relative;
    border-radius: 4px;

    &.dragging {
      opacity: 0.5;
      background: #FAF9FE;
    }

    &.drag-over {
      border-top: 2px solid #554EF1;
    }

    &__active {
      background: #FAF9FE;
    }
    &__header--active{
      background-color: #F1F1FE;
      .rule-group__title{
        color: #554EF1;
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &__header {
      border-radius: 6px 6px 0px 0px;
      font-family: Inter;
      color: #333333;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      cursor: pointer;
      &:hover:not(.rule-group__header--active){
        background-color: #F2F2F2;
      }
  

      // &:hover &--selected{
      //   cursor: pointer;
      //   border: 1px solid #554EF1;
      // }

      &--selected {
        font-weight: 500;
        color: #554EF1;
        background: #F1F1FE;
      }

      .grab-icon {
        display: none;  // Hide by default
      }

      &:hover .grab-icon {
        display: block;  // Show only when hovering over header
      }
    }

    &__action--active {
      border: 1px solid transparent;
      &:hover {
        border-color: #554EF133;
      }
    }

    &__add-action-container {
      position: relative;
      display: flex;
      align-items: center;
    }

    &__title {
      display: flex;
      align-items: center;
      color: #333;
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;

      
      .rule-icon {
        font-size: 16px;
      }
    }

    &__content {
      position: relative;
    }

    // Ensure grab icon stays hidden when hovering over other elements
    &__content:hover ~ .rule-group__header .grab-icon,
    &__add-action-container:hover ~ .rule-group__header .grab-icon {
      display: none;
    }
  }

  .bottom-actions {
    display: flex;
    height: 40px;
    border-top: 1px solid #E6E6E6;
    align-items: center;
    padding: 5px 4px 6px 4px;
    gap: 4px;
    &__add-menu-container {
      width: 151px;
      position: absolute;
      .dropdown {
        width: 151px;
        height: 28px;
        padding: 4px 8px 4px 12px;
      }
      .medium-text {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }

    .add-button, .remove-button {
      border: none;
      background: white;
      cursor: pointer;
      padding: 0px;
      height: 32px;
      &:hover {
        background-color: #F2F2F2;
        border-radius: 5.33px;
      }
    }
  }

  &__right-panel {
    flex: 65%;
    padding: 12px 16px 12px 16px;
    height: 100%;
    overflow-y: auto;
  }

  &__controls {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__select {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: white;
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__target {
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;
      font-size: 12px;
      margin-bottom: 15px;
    }
  }

  &__target-controls {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__control-group {
    flex: 1;
    
    label {
      display: block;
      margin-bottom: 5px;
      color: #666;
    }

    select {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }

  &__rules {
    margin-bottom: 20px;
  }

  &__rule {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
  }

  &__rule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__action-item {
    background: #e8eaf6;
    padding: 8px 12px;
    border-radius: 4px;
    color: #3f51b5;
  }

  &__rule-input {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #838383;
    justify-content: space-between;
    gap: 10px;
    width: 295px;
    height: 28px;
    padding: 8px 6px 8px 12px;
    border-radius: 6px;
    border: 1px solid #D9DBDD
  }

  &__rule-edit-button {
    border: none;
    background: none;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }

  &__footer {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }

  &__add-button,
  &__remove-button {
    border: 1px solid #e0e0e0;
    background: white;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    
    &:hover {
      background: #f5f5f5;
    }
  }

  &__control-row {
    display: flex;
    gap: 12px;
  }

  &__section-add-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
  }
  &__section-add-button {
    border: none;
    background: none;
    cursor: pointer;
}

  &__section-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__section-header {
    //styleName: OVERLINE heading;
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #A5A7AB;
  }
  &__action-config {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__control-group {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .configure-event__control-group-label {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    .configure-event__control-group-dropdown {
      .dropdown{
        width: 200px;
        height: 28px;
      }
    }
  }

  &__select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: white;
    
    &:focus {
      outline: none;
      border-color: #0066cc;
    }
  }

  &__event-dropdown {
    width: 200px;
    z-index: 10;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    height: 180px;
    width: 356px;

    .empty-state__icon {
      width: 80px;
      height: 80px;
      min-height: 80px;
      border-radius: 714.29px;
      background-color: #D9D9D966;
      display: block;
    }

    .empty-state__text-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
    }
    .empty-state__dropdown {
      position: absolute;
      .dropdown{
      width: 160px;
      background: #2A24B0;
      padding: 10px 8px 10px 12px;
      border-radius: 6px;
      top: 54px;
      .dropdown-header{
        background-color: #2A24B0;
      }
      .medium-text{
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.73px;
        color: white;
      }
      .dropdown-header_container{
        img {
          // Makes the SVG white
          filter: brightness(0) saturate(100%) invert(100%);
        }
      }
    }
    }

    .empty-state__text {
      color: #5C5C5C;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .empty-state__button {
      background-color: #6366F1;
      color: white;
      padding: 8px 16px;
      cursor: pointer;
      width: 160px;
      height: 32px;
      padding: 10px 8px 10px 12px;
      gap: 4px;
      border-radius: 6px; 

      &:hover {
        background-color: darken(#6366F1, 5%);
      }
    }
  }

  &__rule-config {
    height: 100%;
    
    .rule-configuration {
      &__section {
        margin-bottom: 24px;
      }

      &__label {
        font-size: 14px;
        font-weight: 500;
        color: #333;
        margin-bottom: 12px;
      }

      &__condition-builder {
        width: 100%;
      }

      &__dropdown {
        width: 100%;
      }
    }
  }

  &__rule-name-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #0066cc;
    }
  }
}

.configure-event__control-group-target-dropdown {
  .item-button{
    font-size: 12px;
  }
  .breadcrumb{
    font-size: 12px;
  }
}

.add-menu-container {
  position: relative;
  height: 32px;
}

.add-menu-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 40px;  
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  height: 84px;
  padding: 8px;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #0505521A;
  box-shadow: 0px 4px 8px 0px #00000029;


  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 9.5px 8px 9.5px 12px;
    border-radius: 4px;
    justify-content: flex-start;
    color: #050552CC;
    border: none;
    background: none;
    cursor: pointer;
    //styleName: Body 2;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    &:hover {
      background: #F8F8FA;
    }
  }


  button {
    display: flex;
height: 32px;
padding: 9.5px 8px 9.5px 12px;
border-radius: 4px 0px 0px 0px;
justify-content: flex-start;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &--centered {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
  }
}
.auto-height-section{
  
}
.action-container {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  &.selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.nested-action {
    margin-left: 20px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: -12px;
      top: 50%;
      width: 8px;
      height: 1px;
      background-color: #ccc;
    }
  }
}

.action-content {

  .rule-label {
    color: #666;
    font-size: 0.9em;
  }
}

.rule-group {
  position: relative;
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: center;  
    padding: 6px;
    border-radius: 6px 6px 0px 0px;
  }

  &__number {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F0F1F8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    z-index: 1;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    color: #333;
    
    .rule-icon {
      font-size: 16px;
    }
  }

  &__content {
    position: relative;
    
  }

  &__action {
    
    // Add horizontal dash
    &::before {
      content: "";
      position: absolute;
      left: 16px;
      top: 50%;
      width: 32px;
      height: 2px;
      background-color: #E6E8F0;
      z-index: 0;
    }


    &--selected {
      background: #F1F1FE;
      .rule-group__action-content .action-name{
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #554EF1;
      }

    }
  }
  &__action-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .action-name{
      //styleName: Body 2;
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 18px;
color: #333333;



    }
  }
}

.action-content {
  display: flex;
  align-items: center;
padding: 8px 6px 8px 6px;
border-radius: 6px;
gap: 4px;
flex: 1;
min-width: 0;


  .action-icon {
    width: 20px;
    height: 20px;
    
    .icon-text {
      font-size: 14px;
      color: #666;
    }
  }

  .action-name {
    color: #333;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

.operation-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 1000;
  
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    
    &:hover {
      background: #f0f2f8;
    }

    .icon-text {
      color: #666;
    }
  }
}

.rule-configuration {
  padding: 20px;

  &__header {
    margin-bottom: 24px;
  }

  &__label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #4a90e2;
    }

    &::placeholder {
      color: #999;
    }
  }

  &__edit-button {
    background: none;
    border: none;
    padding: 4px 8px;
    color: #666;
    cursor: pointer;
    font-size: 16px;
    
    &:hover {
      color: #333;
    }
  }
}

.rule-group__action {
  padding: 8px 6px 8px 48px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  
  &:hover:not(.rule-group__action--active):not(.rule-group__action--selected){
    background: #F2F2F2;
    cursor: pointer;
  }
  &:hover {
    .three-dot-button {
      opacity: 1;
    }
  }
  
}

.configure-event__no-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-style: italic;
}
.rule-group__action--standalone--selected{
  background: #F1F1FE;
  //styleName: Body 2 - Medium;
  .action-name{
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #554EF1;
  }

}
.rule-group__action--standalone--drag-over{
  height: 2px;
  background: rgba(85, 78, 241, 1);
  border-radius: 100px;
  width: 100%; /* Ensure it spans the full width of the component */
  position: relative; /* Ensure it's correctly positioned relative to other content */
  pointer-events: none;
  z-index: 1;
}
.rule-group__action--standalone{
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  &:hover{
    z-index: 2;
}
  


  .action-number {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border: 0.75px solid #C4C4C4;
    transition: opacity 0.2s ease;
  }

  .grab-icon {
    position: absolute;
    left: 6px;
    top: 9px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: grab;
    border-radius: 50%;
    z-index: 2;
  }
  &:hover:not(.rule-group__action--standalone--selected) {
    background: #F2F2F2;
    z-index: 2;
  }
  &:hover {

    .action-number {
      opacity: 0;
    }
    .three-dot-button {
      opacity: 1;
    }
    
    .grab-icon {
      cursor: grab;
      opacity: 1;
    }
  }
}

.action-menu {
  position: relative;

  .three-dot-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .action-dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 120px;
    z-index: 1100;
    padding: 4px 0;

    button {
      width: 100%;
      padding: 8px 16px;
      border: none;
      background: none;
      text-align: left;
      cursor: pointer;
      font-size: 14px;
      color: #333;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: #f5f5f5;
      }

      &.remove-button {
        color: #dc3545;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.action-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 120px;
  z-index: 1100;
  padding: 4px 0;

  button {
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.remove-button {
      color: #dc3545;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}
.configure-event__action-name-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #0066cc;
  }
}


// Add these styles for the temporary text-based icons
.icon-text {
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

// Update the existing styles to accommodate text-based icons

.three-dot-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  font-size: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  .icon-text {
    line-height: 1;
  }
}

// Add these styles for the DebouncedTextEditor integration
.action-name-editor {
  flex: 1;
  margin: -4px 0;
  
  textarea.inputText {
    width: 100%;
    min-height: 24px;
    height: 24px;
    padding: 2px 8px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    font-family: inherit;
    
    &:focus {
      outline: none;
      border-color: #4a90e2;
    }
  }
}

// Add styles for operation menu
.operation-menu {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 182px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  width: 182px;
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 4px 8px 0px #00000029;
  padding: 4px;

  
  button {
    width: 100%;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    
    &:hover {
      background: #F5F5F5;
    }

    .operation-menu__text {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

// Add smooth animation for menu appearance
.operation-menu {
  animation: menuAppear 0.2s ease;
}

@keyframes menuAppear {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Update rule group styles

.rule-group {
  &__number-container {
    width: 20px;
    height: 20px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    
    .rule-menu {
      margin-left: auto;
      position: relative;
      
      .three-dot-button {
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
    &:hover {
  
      .rule-group__number {
        opacity: 0;
      }
      .three-dot-button {
        opacity: 1;
      }
      
      .grab-icon {
        cursor: grab;
        opacity: 1;
      }
    }
  }
  &__number{
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border: 0.75px solid #C4C4C4;
    transition: opacity 0.2s ease;
  }
  .rule-name-editor {
    display: inline-block;
    min-width: 100px;
  }
  .grab-icon {
    position: absolute;
    left: 6px;
    top: 9px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: grab;
    border-radius: 50%;
    z-index: 2;
  }
  &__add-action {
    display: flex;
    align-items: center;
    width: 182px;
    height: 28px;
    padding: 6pxx;
    gap: 4px;
    border-radius: 6px;
    border: 0.75px solid #D9DBDD;
    background-color: white;
    justify-content: center;
    .plus-icon {
      width: 16px;
      height: 16px;
    }
    .rule-group__add-action-text {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #616161;
    }
    &:hover {
      cursor: pointer;
      background: #F5F5F5;
    }
  }
 
}
.rule-group__header,
.rule-group__action,
.rule-group__action--standalone {
  position: relative;
}


.action-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.rule-group:last-child::before {
  bottom: 30px;
}


.rule-group__add-action-container {
  position: relative;
  margin-left: 40px;
  height: 36px;
  
  &::before {
    content: '';
    position: absolute;
    left: -25px;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: #E6E8F0;
    z-index: 0;
  }
}

.three-dot-button {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.rule-group__header:hover .three-dot-button,
.rule-group__action:hover .three-dot-button {
  opacity: 1;
  visibility: visible;
}

.rule-group__action {
  &:hover {
    .three-dot-button {
      opacity: 1;
      visibility: visible;
    }
    
    // Hide other three-dot buttons when hovering over an action
    ~ .rule-group__action .three-dot-button,
    .rule-group__header .three-dot-button {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.rule-group__header {
  &:hover {
    .three-dot-button {
      opacity: 1;
      visibility: visible;
    }
    
    // Hide operation three-dot buttons when hovering over the header
    ~ .rule-group__content .three-dot-button {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.rule-group__action--standalone {
  &:hover {
    .three-dot-button {
      opacity: 1;
      visibility: visible;
    }
    
    // Hide other three-dot buttons when hovering over a standalone action
    ~ .rule-group__action--standalone .three-dot-button {
      opacity: 0;
      visibility: hidden;
    }
  }

  .three-dot-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
}
