.modal-heading-container{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 1px solid rgba(5, 5, 82, 0.1)
  }
  .modal-heading{
    display: flex;
    gap: 4px;
    flex-direction: column;
    &__firstPart{
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: rgba(5, 5, 82, 0.8);
    }
    &__secondPart{
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: rgba(5, 5, 82, 0.6);
    }
  
  }
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    outline: none;
    display: flex;
    align-items: flex-start;
    height: 24px;
    width: 24px;
  }
  .error-modal{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 50%;
    max-width: 548px;
    min-width: 400px;
    max-height: 80%;
    position: absolute;
    overflow: auto;
  }
  .error-modal-body {
    padding: 20px;
  }
  .error-modal-content{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .error-section{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    &__open{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid rgba(5, 5, 82, 0.1);
      padding: 12px;
    }
    // p {
    //     font-weight: bold;
    //     font-size: 14px;
    //   }
  }
  .error-modal-compatibility-message{
    color: rgba(191, 38, 0, 1);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .error-modal-issue-container{
    background: rgba(191, 38, 0, 0.1);
    padding: 12px 16px 12px 16px;
  }
  
  .error-section-summary{
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
  }
  
  .error-section-content{
    padding: 16px 8px 16px 14px;
    gap: 12px;
    border-top: 1px solid rgba(5, 5, 82, 0.1);
    width: 100%;
  
  }
  .error-items{
    font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
  
  
  }
  .error-section-summary-container{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    width: 100%;
    &__open{
      display: flex;
      flex-direction: row;
      padding: 0px 8px 12px 4px;
      gap: 8px;
      border: 0px 0px 1px 0px;
      width: 100%;
      &:hover{
        cursor: pointer;
      }
    }
    &:hover{
      cursor: pointer;
    }
  
  }
  .error-section-items{
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 0px;
    word-break: break-all; 
  }
  .error-modal-footer{
    padding: 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 12px;
    border-radius: 0px 0px 6px 6px;
    border: 1px 0px 0px 0px;
    justify-content: flex-end;
  }
  .error-modal-footer-confirmation{
    padding: 12px;
    border-radius: 4px;
    background: rgba(5, 5, 82, 0.03);
    .MuiFormControlLabel-root {
      display: flex;
      gap: 6px;
      position: relative;
      left: 10px;
      font-family: Inter;
      font-size: 13px;
      line-height: 20px;
      text-align: left;
  
      .MuiTypography-root {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: rgba(5, 5, 82, 0.6);
      }
  
  }
  }
  .error-modal-save{
   display: flex;
   justify-content: flex-end;
   gap: 8px;
   &__button{
      height: 36px;
      min-width: 64px;
      padding: 10px 12px 10px 12px;
      color: rgba(5, 5, 82, 0.8);
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 6px;
      border: 1px solid rgba(5, 5, 82, 0.2);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
   }
  }
  .error-modal-save__button__disabled {
    @extend .error-modal-save__button; // Inherit all styles from error-modal-save__button
    background-color: rgba(245, 245, 247, 1); // Override specific styles for disabled button
    cursor: not-allowed; // Change cursor to indicate disabled state
    color: rgba(5, 5, 82, 0.3);
  }
  .error-modal-save__button__active {
    @extend .error-modal-save__button; // Inherit all styles from error-modal-save__button
    background: rgba(85, 78, 241, 1);
    color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .accordion-icon{
    height: 24px;
    width: 24px;
  }
  .blue-text {
    color: rgba(85, 78, 241, 1);
    font-family: IBM Plex Mono;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
  .error-modal-container{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 50%;
    max-width: 680px;
    min-width: 400px;
    position: absolute;
    overflow: auto;
    min-height: 64%;
    display: flex;
    max-height: 64%;
  }
  .error-modal-sub-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }