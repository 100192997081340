.error-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  outline: none;

  height: 24px;
  width: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
  }
}