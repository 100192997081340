@import '../../../App.scss';

$darkBlue: rgba(5, 5, 82, 0.8);
$lightBlue: rgba(5, 5, 82, 0.1);
$darkGray: rgba(5, 5, 5, 0.05);
$purple: #554ef1;

.conditionalVar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
    z-index: 9999;

    &__properties_tab {
        position: fixed;
        width: 120%;
        height: 100%;
        top: 0;
        left: -10%;
        z-index: 999999 !important;
        background-color: rgba(5, 5, 82, 0.37);
        backdrop-filter: blur(1px);
        overflow-y: auto;
        border-radius: 0.375rem;
        min-height: 640px;

        &__content {
            background-color: white;
            opacity: 1;
            width: 100%;
            box-shadow: 0 0 0.9375rem 0 rgba(5, 5, 82, 0.1);
            border-radius: 0.375rem;
            right: 0;
            height: 100%;
            padding-bottom: 10px;
        }
    }

    &__display_title {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;

        &__close {
            background-color: unset;
            border: none;
            cursor: pointer;
        }
    }

    &__display {
        font-family: $font_family;
        font-weight: 500;
        color: $darkBlue;
        margin-bottom: 12px;
    }

    &__add {
        background-color: unset;
        font-family: $font_family;
        font-weight: 500;
        border-radius: 6px;
        height: 31px;
        color: $purple;
        border: none;
        cursor: pointer;
    }

    &__div {
        display: flex;
        flex-direction: column;
        padding: 12px;
        height: 100px;
    }

    &__conditions {
        &_container {
            padding-inline: 12px;
            height: calc(100% - 50px);
            overflow-y: scroll;
        }

        &_title {
            margin-top: 12px;
            margin-bottom: 12px;
            font-family: $font_family;
            font-size: 12px;
            font-weight: 500;
            color: $darkBlue;
        }
    }

    &__content {
        background-color: white;
        opacity: 1;
        width: 80%;
        height: 80vh;
        position: absolute;
        top: 10%;
        left: 10%;
        border-radius: 0.375rem;
        box-shadow: 0 0 0.9375rem 0 $lightBlue;
        padding: 12px;
    }

    &__name {
        font-family: $font_family;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $darkBlue;
        margin-bottom: 12px;
        margin-top: 12px;

        &__value {
            font-family: IBMPlexMono;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: left;
            color: rgba(150, 38, 31, 0.8);
            margin-right: 6px;
            height: 24px;
            display: flex;
            align-items: center;
            padding: 4px 6px;
            border-radius: 6px;
            background-color: #f8f8fa;
        }

        &__input {
            font-family: $font_family;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $darkBlue;
            width: 204px;
            height: 31px;
            padding: 8px;
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
            border: solid 1px $lightBlue;
        }
    }

    &__title {
        font-family: $font_family;
        font-size: 14px;
        font-weight: 500;
        color: #050552;


        &__div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $lightBlue;
            padding: 8px 12px;
            height: 50px;
        }

        &__create {
            color: white;
            font-family: $font_family;
            border-radius: 6px;
            height: 31px;
            background-color: $purple;
            border: none;
            margin-right: 12px;
            cursor: pointer;
            padding: 8px 16px;

            &:disabled {
                background-color: #554ef191;
                cursor: not-allowed;
            }

        }

        &__close-div {
            display: flex;
            align-items: center;
        }
    }
}

.add-rule {
    color: $purple;
    font-family: $font_family;
    border: none;
    background-color: unset;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin: 6px;

}

.condition-var {
    &__content__body {
        margin-top: 1rem;
        font-family: $font_family;
        font-size: 0.75rem;
        font-weight: normal;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $darkBlue;
        height: 90%;
    }
}

.conditional-var_value {
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-top: 6px;
}

.is_true {
    font-family: $font_family;
    font-size: 12px;
    color: $darkBlue;
    font-weight: normal;
    margin-right: 4px;
}

.then {
    font-family: $font_family;
    font-size: 12px;
    font-weight: normal;
    color: rgba(5, 5, 82, 0.4);
    margin-right: 6px;
}

.individual_conditional_var_div {
    height: 56vh;
    overflow: scroll;
}

.add-else-if {
    height: 31px;
    border-radius: 6px;
    border: solid 1px $purple;
    background-color: unset;
    font-family: $font_family;
    color: $purple;
    font-weight: 500;
}

.conditional-variable {
    &__picker {
        position: absolute;
        height: 196px;
        padding: 12px 0;
        border-radius: 6px;
        box-shadow: 0 5px 15px 0 $lightBlue;
        border: solid 0.5px $lightBlue;
        background-color: #ffffff;

        width: 212px;
        z-index: 99999999 !important;

        &__content {
            overflow: scroll;
            height: 90%;
        }

        &__add-div {
            border: solid 0.5px $lightBlue;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            &:hover {
                background-color: $lightBlue;

            }
        }

        &__value {
            font-family: $font_family;
            padding-left: 12px;
            font-size: 12px;
            color: $darkBlue;
            cursor: pointer;
            line-height: 31px;

            &:hover {
                background-color: $lightBlue;
            }
        }
    }
}

.conditional-var-value-dropdown {
    width: 30%;
    font-family: $font_family;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.condVar {
    &__table {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        border: solid 1px $darkGray;

        &__header {
            width: 100%;
            padding: 8px;
            display: flex;
            justify-content: space-between;
            position: relative;
            align-items: center;
            border-bottom: solid 1px $darkGray;
            background-color: rgba(85, 78, 241, 0.03);
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.24px;
            color: rgba(5, 5, 82, 0.6);
            height: 40px;
        }

        &__row {
            width: 100%;
            padding: 8px;
            display: flex;
            justify-content: space-between;
            position: relative;
            min-height: 76px;
            align-items: center;
            border-bottom: solid 1px $darkGray;

            &_even {
                background-color: rgba(85, 78, 241, 0.03);
            }
        }

        &__cell {
            width: 100%;
            font-family: Inter;
            font-size: 12px;
            height: 100%;
            overflow-y: scroll;
            display: flex;
            align-items: center;
        }


        &_var {

            &__name {
                min-height: 24px;
                padding: 8px;
                border-radius: 6px;
                background-color: #f8f8fa;
                font-size: 12px;
                text-align: left;
                font-weight: 600;
                color: rgba(150, 38, 31, 0.8);
                display: flex;
                align-items: center;
                max-width: 160px;
                display: inline-block;
                word-break: break-word;
            }

            &__values {
                min-height: 24px;
                padding: 8px;
                border-radius: 6px;
                font-size: 12px;
                text-align: left;
                display: flex;
                align-items: center;
                max-width: 180px;
                display: inline-block;
                word-break: break-word;

                &__item {
                    padding-block: 2px;
                    color: $darkBlue;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }

        &__modifyBtn {
            &_container {
                opacity: 0;
                pointer-events: none;
                border-radius: 6px;
                box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
                border: solid 1px #e1e1ee;
                position: absolute;
                right: 0;
                transform: translateX(50%);
                background-color: #ffffff;
            }

            height: 24px;
            border-radius: 6px;
            background: none;
            border: none;
            font-size: 12px;
            cursor: pointer;
            width: 24px;
            display: flex;
            gap: 4px;
            padding: 4px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__row:hover .condVar__table__modifyBtn_container {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.4s ease;

        }
    }

    &_addBtn {
        padding: 8px 12px;
        border-radius: 6px;
        background: none;
        border: solid 1.2px $purple;
        font-size: 12px;
        text-align: left;
        color: $purple;
        cursor: pointer;
        font-weight: 600;
        width: 185px;
        display: flex;
        gap: 4px;
    }
}