@import '../../../App.scss';


.description-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 25px;

  &:has(input:disabled)>&__label {
    color: rgba(5, 5, 82, 0.4);
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: rgba(5, 5, 82, 0.8);
    font-family: $font_family;
  }
}