@import '../../../App.scss';

.add-logo-div {
    font-family: $font_family;
    color: rgba(5, 5, 82, 0.8);
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 14%;
    padding: 0;
    flex: 0 0 auto;
    margin-top: 20px;

    &__layout {
        width: 100%;
        height: 100%;

    }
    &__container {
        width: 100%;
        height: 100%;

    }
    &__name {
        width: 136px;
        height: 15px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        text-align: left;

    }

    &__upload-button {
       display: none;
    }
    &__upload{
        width: 117px;
        height: 32px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 9px 8px;
        border-radius: 6px;
        background-color: rgba(5, 5, 82, 0.04);
        cursor: pointer;
        &__label1{
            width: 73px;
            display: block;
            height: 15px;
            font-family: Inter;
            flex-shrink: 0;
            font-size: 12px;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
        }
        &__logo{
            width: 16px;
            height: 16px;
            flex-grow: 0;
        }
    }

}

.preview{
    width: 160px;
    height: 64px;
    flex-grow: 0;
    padding: 22px 26px 22px 20px;
    border-radius: 6px;
    background-color: rgba(5, 5, 82, 0.04);
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;

}
.preview-logo{
    max-width: 100%;
}
.preview-lottie{
    max-width: 100%;
    font-size: 0.75rem;
    font-family: Inter;
    overflow-wrap: break-word;
}
.preview-container{
    display: flex;
    flex-direction: row;
}
.delete{
    max-width: 100%;
    height: 30px;   
    margin-top: 36px; 
    position: relative;
}
.delete-button{
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    cursor: pointer;
}
.dropdown-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}