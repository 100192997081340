.secondary-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #05055233;
  background-color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  color: #050552CC;
  height: 36px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #f5f5f5;
  }

  // When loading is active
  .MuiCircularProgress-root {
    margin-right: 4px;
  }
}